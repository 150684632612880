<template>
  <div>
    <view-all-data type="historyEtest" title="Riwayat Etest" />
  </div>
</template>

<script>
import ViewAllData from "../../components/ViewAllData.vue";
export default {
  components: { ViewAllData },
};
</script>
